<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de solicitudes pendientes de respuesta</h1>
                        <h5 class="subtitle">Clase de documento: {{ tcr_nmbre }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reportePendiente" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Año</th>
                                    <th>Consecutivo</th>
                                    <th>Fecha Radicación</th>
                                    <th class="entidad">Entidad</th>
                                    <th class="asunto">Asunto</th>
                                    <th>Ciudad</th>
                                    <th class="dependencia">Dependencia</th>
                                    <th class="serie">Serie</th>
                                    <th>Tiempo respuesta</th>
                                    <th>Tiempo restante</th>
                                    <th>Último seguimiento</th>
                                    <th>Fecha último seguimiento</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.dcm_ano }}</td>
                                    <td>{{ item.dcm_cdgo }}</td>
                                    <td>{{ item.dcm_fcharad | formatDate }}</td>
                                    <td>{{ item.dcm_entidad }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dcm_ciudad }}</td>
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td>{{ item.dcm_tres }}</td>
                                    <td>{{ item.restante }}</td>
                                    <td>{{ item.ParaUsuarioNombre }}</td>
                                    <td>{{ item.FechaSeguimiento | formatDate }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      tcr_nmbre: '',
      showProgress: false,
      dnl: []
    }
  },
  filters: {
    formatDate
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');

    this.fetchDependencia(err => {
      if (err){
        alert('Error al consultar las dependencias.');
      } else {
        this.fetchSerie(err => {
          if (err){
            alert('Error al consultar las series.');
          } else {
            this.fetchDianol(err => {
              if (err){
                alert('Error al consultar los días no laborales.');
              } else {
                this.debug = this.$route.params;
                this.tcr_nmbre = this.$route.params.tcr_nmbre;
                this.searchItems(this.$route.params.query);
              }
            });
          }
        });
      }
    });    
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchSerie(callback){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDianol(callback){
      var p = {};
      p.cmp_id = this.$store.state.company;
      p.inicio = this.$route.params.query.dcm_fcharadini;
      p.fin = this.$route.params.query.dcm_fcharadfin;

      let uri = '/dianols/rango';
      this.axios.post(uri, p)
      .then((response) => {
        this.dnl = response.data;
        return callback(false);
      })
      .catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/pendiente';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (var j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                if (list[i].dcm_tres == undefined){
                  list[i].dcm_tres = this.series[j].ser_tres;
                }
                j = this.series.length;
              }
            }

            let restante = parseInt(list[i].dcm_tres) - tiempoUtilizado(list[i].dcm_fcharad, new Date(), this.dnl);
            list[i].restante = restante;

            if (list[i].usuario_docs.length > 0){
              list[i].ParaUsuarioNombre = list[i].usuario_docs[0].usu_nmbre + ' ' + list[i].usuario_docs[0].usu_aplldo;
            }

            if (list[i].seguimientos.length > 0){
              list[i].FechaSeguimiento = list[i].seguimientos[0].Fecha;
            }

            if (p.mora){
              if (list[i].restante < 0){
                this.items.push(list[i]);
              }
            } else {
              this.items.push(list[i]);
            }
          }
          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteSolicPendRespuesta';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.index = i + 1;
        item.dcm_ano = this.items[i].dcm_ano;
        item.dcm_cdgo = this.items[i].dcm_cdgo;
        item.dcm_fcharad = moment(this.items[i].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
        item.dcm_entidad = this.items[i].dcm_entidad;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dcm_ciudad = this.items[i].dcm_ciudad;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_tres = this.items[i].dcm_tres;
        item.restante = this.items[i].restante;
        item.ParaUsuarioNombre = this.items[i].ParaUsuarioNombre;
        if (this.items[i].FechaSeguimiento){
          item.FechaSeguimiento = moment(this.items[i].FechaSeguimiento).format('DD/MMM/YYYY hh:mm A');
        } else {
          item.FechaSeguimiento = '';
        }

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de solicitudes pendientes de respuesta"],
      	["Tipo de documento: " + this.tcr_nmbre],
        [],
        ["#", "Año", "Consecutivo", "Fecha Radicación", "Entidad", "Asunto", "Ciudad", "Dependencia", "Serie", "Tiempo respuesta	", "Tiempo restante", "Último seguimiento", "Fecha último seguimiento"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
